
function SignIn_img(){
    return <>
         <div className="right_data mt-5" style={{width: "100%"}}>
                    <div className='sign_img mt-5'>
                        <img src="./sign.svg" className="lg_img" alt=""/>
                    </div>
                </div>
    </>
}

export default SignIn_img